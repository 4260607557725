import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Document = _resolveComponent("Document")!
  const _component_Notes = _resolveComponent("Notes")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_Document, { id: "typescale-document" }),
    _createVNode(_component_Notes, {
      documents: _ctx.documents,
      ids: _ctx.ids
    }, null, 8, ["documents", "ids"])
  ]))
}