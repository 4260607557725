
import { defineComponent } from "vue";
import Document from "@/components/typescale/Document.vue";
import Notes from "@/components/typescale/Notes.vue";
export default defineComponent({
  name: "Typescale",
  components: {
    Document,
    Notes,
  },
});
